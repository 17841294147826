import { CSSProperties, ReactNode } from 'react'

import { cn } from '../../lib/utils/cn'

export type Props = {
  children: ReactNode
  className?: string
  style?: CSSProperties
}

export const IconBox = ({ children, className, style }: Props) => {
  return (
    <div
      className={cn(
        'flex items-center bg-page-icon-box-background justify-center rounded-page-widget-block shadow-page-icon-box border-page-icon-box text-page-icon size-fluid-8 shrink-0',
        className,
      )}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  )
}
