import { PartialMessage } from '@bufbuild/protobuf'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'

import imageLoader, { unsplashLoader } from '@/lib/utils/image-loader'

export const getMediaImageSrc = ({
  media,
  width,
  height,
  format,
  quality,
}: {
  media?: PartialMessage<Media>
  width: number
  height: number
  format?: string
  quality?: number
}) => {
  if (media?.type === 'ASSET') {
    return imageLoader({
      src: `${media?.assetBucket}/${media?.assetSrc}`,
      width,
      height,
      format,
      quality,
    })
  } else if (media?.type === 'UNSPLASH') {
    return unsplashLoader({
      src: media?.unsplashUrl || '',
      width,
      height,
      format,
      quality,
    })
  } else if (media?.type === 'URL') {
    return media?.customUrl
  } else if (media?.type === 'BRANDFETCH') {
    return media?.brandfetchUrl
  } else if (media?.type === 'ICONS8') {
    return media?.icons8Url
  }

  return null
}
