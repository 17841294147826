import { PartialMessage } from '@bufbuild/protobuf'
import { ContactContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'
import { ArrowInboxIcon, SendIcon } from '@fingertip/icons'
import { kebabCase } from 'change-case'
import { observer } from 'mobx-react-lite'
import NextImage from 'next/image'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createVCard } from '@/components/blocks/contact/utils'
import { BlockProps } from '@/components/blocks/shared/block-switch'
import { getMediaImageSrc } from '@/components/blocks/shared/media.utils'
import { QrHtmlInnerElement } from '@/components/qr/qr-html-inner-element'
import { Spinner } from '@/components/shared/spinner'
import { Button } from '@/components/ui/button'
import { DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { useBreakpoint } from '@/lib/hooks/use-breakpoint'
import imageLoader from '@/lib/utils/image-loader'

import { BlockType } from '../../shared/types'

type Props = {
  content: PartialMessage<ContactContent>
  context: 'SITE_OWNER' | 'SITE_CONTACT'
  next?: () => void
  block?: BlockType
} & Pick<BlockProps, 'handleClick' | 'siteSlug'>

const blobToBase64 = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (!reader.result) {
        reject(new Error('FileReader result is null'))
        return
      }
      // Ensure we get a string result
      const base64String =
        typeof reader.result === 'string'
          ? reader.result
          : Buffer.from(reader.result).toString('base64')
      resolve(base64String)
    }
    reader.onerror = () => reject(new Error('Failed to convert blob to base64'))
    reader.readAsDataURL(blob)
  })
}

export const SaveContactQr = observer(
  ({ block, handleClick, content, context, next, siteSlug }: Props) => {
    const { t } = useTranslation()
    const breakpoint = useBreakpoint()
    const isDesktop = ['lg', 'xl'].includes(breakpoint)
    const [vCardBlob, setVCardBlob] = useState<Blob | MediaSource | null>(null)
    const [vCardString, setVCardString] = useState<string>('')

    const handleCreateContact = useCallback(() => {
      if (block) {
        handleClick?.(block)
      }

      if (!vCardBlob) {
        return
      }

      // Create a link element to trigger the download
      const link = document.createElement('a')
      link.href = URL.createObjectURL(vCardBlob)
      link.download = `${kebabCase(content?.fullName || '')}.vcf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }, [block, content?.fullName, handleClick, vCardBlob])

    const handleSave = useCallback(() => {
      handleCreateContact()
      next?.()
    }, [handleCreateContact, next])

    useEffect(() => {
      const fetchData = async () => {
        const src = getMediaImageSrc({
          media: content?.media,
          width: 260,
          height: 260,
          format: 'jpeg',
        })

        let base64Image = ''

        if (!isDesktop && src) {
          try {
            const response = await fetch(src)

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`)
            }

            const blob = await response.blob()
            base64Image = await blobToBase64(blob)
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching image:', error)
          }
        }

        const { vCardString, vCardBlob } = createVCard({
          content,
          context,
          base64Image,
          siteSlug,
        })
        setVCardBlob(vCardBlob)
        setVCardString(vCardString)
      }

      fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content?.media])

    return (
      <>
        <DialogHeader className="w-full pb-2 pt-4">
          <DialogTitle className="text-center">
            {isDesktop
              ? t('scan_qr_code_to_save_contact')
              : t('scroll_down_to_create_new_contact')}
          </DialogTitle>
        </DialogHeader>

        {isDesktop ? (
          <>
            <div className="my-5 flex w-full justify-center">
              {vCardString ? (
                <QrHtmlInnerElement
                  data={vCardString}
                  theme={{
                    id: 'custom',
                    key: 'Custom',
                    logoColor: 'currentColor',
                    foregroundColor: 'currentColor',
                    backgroundColor: 'currentColor',
                    borderColor: 'currentColor',
                  }}
                  hasLogo={false}
                  className="aspect-square w-full"
                  style={{
                    maxHeight: 200,
                    maxWidth: 200,
                  }}
                  correction="H"
                />
              ) : (
                <Spinner />
              )}
            </div>

            <Button
              onClick={handleCreateContact}
              className="w-full"
              title={t('save_contact')}
            >
              <ArrowInboxIcon className="mr-2" />
              {t('save_to_contacts')}
            </Button>

            {next && (
              <Button
                onClick={next}
                className="w-full"
                title={t('save_contact')}
                variant="secondary"
              >
                <SendIcon className="mr-2 size-4" />
                {t('share_your_details')}
                {content?.fullName ? ` with ${content?.fullName}` : ''}
              </Button>
            )}
          </>
        ) : (
          <>
            <NextImage
              src="b136ead4-54f9-416d-e17b-9a39c14af400"
              alt={t('add_to_contact_on_iphone')}
              className="mx-auto w-full max-w-[400px] py-4"
              width={558}
              height={572}
              loader={imageLoader}
            />

            <Button
              onClick={handleSave}
              className="w-full"
              title={t('save_contact')}
            >
              <ArrowInboxIcon className="mr-2" />
              {t('save_to_contacts')}
            </Button>

            {next && (
              <Button
                onClick={next}
                className="mt-4 w-full"
                title={t('save_contact')}
                variant="secondary"
              >
                <SendIcon className="mr-2 size-4" />
                {t('share_your_details')}
                {content?.fullName ? ` with ${content?.fullName}` : ''}
              </Button>
            )}
          </>
        )}
      </>
    )
  },
)
