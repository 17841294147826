import { useMutation, useQuery } from '@connectrpc/connect-query'
import { FormFieldType } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/form_field_type_pb'
import { FormResponseItem } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/form_response_pb'
import { createFormResponsePublic } from '@fingertip/creator-proto/gen/fingertip/creator/form_response/v1/form_response-FormResponseService_connectquery'
import { getFormTemplatePublic } from '@fingertip/creator-proto/gen/fingertip/creator/form_template/v1/form_template-FormTemplateService_connectquery'
import { ArrowRightIcon } from '@fingertip/icons'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { BlockHeading } from '@/components/form-template/inputs/block-heading'
import { DynamicInput } from '@/components/form-template/inputs/dynamic-input'
import { Button } from '@/components/ui/button'
import { useToken } from '@/lib/hooks/use-token'
import { flattenObject } from '@/lib/utils/utils'

import { AlertBanner } from '../shared/alert-banner'
import { Spinner } from '../shared/spinner'

type Props = {
  onSuccess?: (response: FormResponseItem) => void
  formTemplateSlug?: string
  formTemplateId?: string
  overrideButtonText?: string
  source: string
  siteSlug: string
  disableNotifications?: boolean
}

export const FormPage = ({
  onSuccess,
  formTemplateSlug,
  formTemplateId,
  siteSlug,
  overrideButtonText,
  source,
  disableNotifications,
}: Props) => {
  const { t } = useTranslation()
  const { callOptions } = useToken()

  const {
    data: formTemplateData,
    error: formTemplateError,
    isLoading: formTemplateIsLoading,
  } = useQuery(
    getFormTemplatePublic,
    {
      formTemplateSlug,
      formTemplateId,
      siteSlug,
    },
    {
      callOptions,
      gcTime: 0,
    },
  )

  const fields = formTemplateData?.formFields || []

  const form = useForm({
    defaultValues: {},
  })

  const mutationCreate = useMutation(createFormResponsePublic, {
    callOptions,
    onSuccess: (data) => {
      if (data.formResponse) {
        toast.success(t('form_submitted_successfully'))
        onSuccess?.(data.formResponse)
        form.reset()
      }
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const onSubmit = async (data: Record<string, string>) => {
    const flattenedObject = flattenObject(data)

    mutationCreate.mutate({
      input: flattenedObject,
      formTemplateSlug,
      formTemplateId,
      source,
      disableNotifications: !!disableNotifications,
      siteSlug,
    })
  }

  if (formTemplateError) {
    return (
      <div className="py-4">
        <AlertBanner>{formTemplateError.message}</AlertBanner>
      </div>
    )
  }

  if (formTemplateIsLoading) {
    return (
      <div className="py-4">
        <Spinner />
      </div>
    )
  }

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="flex w-full flex-col"
      id={`form-${formTemplateData?.formTemplate?.slug}`}
    >
      {formTemplateData?.formTemplate?.title && (
        <h3 className="h4 mb-2">{formTemplateData.formTemplate.title}</h3>
      )}
      {formTemplateData?.formTemplate?.description && (
        <div
          className="mb-4 text-sm"
          dangerouslySetInnerHTML={{
            __html: formTemplateData.formTemplate.description.replace(
              /\n/g,
              '<br />',
            ),
          }}
        />
      )}
      {fields.map((field, index) => (
        <div key={field.id} id={field.id}>
          {field.content?.kind === FormFieldType.HEADING ||
          (field.content?.kind as any) === 'HEADING' ? (
            <BlockHeading field={field} key={index} />
          ) : (
            <DynamicInput form={form} field={field} key={index} />
          )}
        </div>
      ))}
      <div className="mt-4">
        <Button
          type="submit"
          loading={mutationCreate.isPending}
          className="w-full"
          id={`form-${formTemplateData?.formTemplate?.slug}-submit`}
        >
          {overrideButtonText ? (
            overrideButtonText
          ) : (
            <>
              {t('submit')}
              <ArrowRightIcon width={18} height={18} className="ml-2" />
            </>
          )}
        </Button>
      </div>
    </form>
  )
}
