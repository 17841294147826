declare global {
  interface Window {
    CelloAttribution?: (action: string) => Promise<string> // Ensure it returns a Promise<string>
  }
}

export const useCello = () => {
  const getUcc = async (): Promise<string | undefined> => {
    if (!!window.CelloAttribution) {
      return (await window.CelloAttribution('getUcc')) as string
    } else {
      return undefined
    }
  }

  return {
    getUcc,
  }
}
