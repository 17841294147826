export type SocialKeys =
  | 'amazon'
  | 'apple-app-store'
  | 'apple-music'
  | 'apple-podcast'
  | 'audible'
  | 'behance'
  | 'discord'
  | 'dribbble'
  | 'email'
  | 'facebook-messenger'
  | 'facebook'
  | 'fingertip'
  | 'github'
  | 'google-play-store'
  | 'instagram'
  | 'linkedin'
  | 'linktree'
  | 'onlyfans'
  | 'phone'
  | 'pinterest'
  | 'snapchat'
  | 'soundcloud'
  | 'spotify'
  | 'sms'
  | 'telegram'
  | 'threads'
  | 'tiktok'
  | 'twitch'
  | 'twitter'
  | 'url'
  | 'vimeo'
  | 'wechat'
  | 'bluesky'
  | 'whatsapp'
  | 'youtube'
  | 'line'

export type SocialType = {
  name: string
  key: string
  validation: string
  prefix?: string
  example: string
  label: string
  buttonLabel: string
  contactType: 'URL' | 'TEL' | 'EMAIL'
  contactLabels?: string[]
  ctaTitle?: string
}

export type SocialsType = Record<SocialKeys, Omit<SocialType, 'key'>>

export const socials: SocialsType = {
  amazon: {
    name: 'Amazon',
    prefix: 'amazon.com/',
    example: 'amazon.com/your-product/dp/B08XYZ1234',
    label: 'Amazon URL',
    buttonLabel: 'Buy',
    validation: '^(https?://)?(www\\.)?amazon\\.com/.*$',
    contactType: 'URL',
  },
  'apple-app-store': {
    name: 'Apple App Store',
    example: 'apps.apple.com/us/yourapp/url1234567890',
    label: 'Apple App Store URL',
    buttonLabel: 'Download',
    validation: '^(https?://)?(www\\.)?apps\\.apple\\.com/.*/.*/.*$',
    contactType: 'URL',
  },
  'apple-music': {
    name: 'Apple Music',
    example: 'music.apple.com/us/album/youralbum',
    label: 'Apple Music URL',
    buttonLabel: 'Listen',
    validation: '^(https?://)?(www\\.)?music\\.apple\\.com/.*/album/.*$',
    contactType: 'URL',
  },
  'apple-podcast': {
    name: 'Apple Podcast',
    example: 'podcasts.apple.com/us/podcast/yourpodcast/123456789',
    label: 'Apple Podcast URL',
    buttonLabel: 'Subscribe',
    validation: '^(https?://)?(www\\.)?podcasts\\.apple\\.com/.*/podcast/.*$',
    contactType: 'URL',
  },
  audible: {
    name: 'Audible',
    prefix: 'audible.com/',
    example: 'audible.com/pd/your-audiobook/B08XYZ1234',
    label: 'Audible URL',
    buttonLabel: 'Listen',
    validation: '^(https?://)?(www\\.)?audible\\.com/pd/.*$',
    contactType: 'URL',
  },
  behance: {
    name: 'Behance',
    prefix: 'https://www.behance.net/',
    example: 'username',
    label: 'Behance',
    buttonLabel: 'View Profile',
    validation: '^[a-zA-Z0-9_-]+$',
    contactType: 'URL',
  },
  discord: {
    name: 'Discord',
    prefix: 'discord.com/invite/',
    example: 'yourchannel',
    label: 'Discord URL',
    buttonLabel: 'Join',
    validation: '(www\\.)?^discord\\.com/invite/.*$',
    contactType: 'URL',
  },
  dribbble: {
    name: 'Dribbble',
    prefix: 'dribbble.com/',
    example: 'username',
    label: 'Dribbble URL',
    buttonLabel: 'Follow',
    validation: '^(https?://)?(www\\.)?dribbble\\.com/.*$',
    contactType: 'URL',
  },
  email: {
    name: 'Email',
    example: 'hello@example.com',
    label: 'Email URL',
    buttonLabel: 'Contact',
    validation: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$',
    contactType: 'EMAIL',
    contactLabels: ['Email', 'Work email'],
  },
  facebook: {
    name: 'Facebook',
    prefix: 'facebook.com/',
    example: 'facebookpageurl',
    label: 'Facebook URL',
    buttonLabel: 'Like',
    validation: '^(https?://)?(www\\.)?facebook\\.com/.*$',
    contactType: 'URL',
  },
  'facebook-messenger': {
    name: 'Facebook Messenger',
    prefix: 'm.me/',
    example: 'page-name',
    label: 'Facebook Messenger URL',
    buttonLabel: 'Chat',
    validation: '^(https?://)?(www\\.)?m\\.me/.*$',
    contactType: 'URL',
  },
  fingertip: {
    name: 'Fingertip',
    prefix: 'fingertip.com/',
    example: 'username',
    label: 'Fingertip page',
    buttonLabel: 'View',
    validation: '^(https?://)?(www\\.)?fingertip\\.com/.*$',
    contactType: 'URL',
  },
  github: {
    name: 'GitHub',
    prefix: 'github.com/',
    example: 'username',
    label: 'GitHub URL',
    buttonLabel: 'Follow',
    validation: '^(https?://)?(www\\.)?github\\.com/.*$',
    contactType: 'URL',
  },
  'google-play-store': {
    name: 'Google Play Store',
    example: 'play.google.com/store/apps/details?url=com.yourapp.app',
    label: 'Google Play Store URL',
    buttonLabel: 'Download',
    validation:
      '^(https?://)?(www\\.)?play\\.google\\.com/store/apps/details[?]url=.*$',
    contactType: 'URL',
  },
  instagram: {
    name: 'Instagram',
    prefix: '@',
    example: 'instagram_handle',
    label: 'Instagram URL',
    buttonLabel: 'Follow',
    validation: '^@[a-zA-Z0-9._]+$',
    contactType: 'URL',
  },
  linkedin: {
    name: 'LinkedIn',
    prefix: 'linkedin.com/in/',
    example: 'username',
    label: 'LinkedIn URL',
    buttonLabel: 'Connect',
    validation: '^(https?://)?(www\\.)?linkedin\\.com/in/.*$',
    contactType: 'URL',
  },
  linktree: {
    name: 'Linktree',
    prefix: 'https://linktr.ee/',
    example: 'username',
    label: 'Linktree',
    buttonLabel: 'View Links',
    validation: '^[a-zA-Z0-9_-]+$',
    contactType: 'URL',
  },
  onlyfans: {
    name: 'OnlyFans',
    prefix: 'onlyfans.com/',
    example: 'username',
    label: 'OnlyFans URL',
    buttonLabel: 'Subscribe',
    validation: '^(https?://)?(www\\.)?onlyfans\\.com/.*$',
    contactType: 'URL',
  },
  phone: {
    name: 'Phone',
    example: '0456000000',
    label: 'Phone number',
    buttonLabel: 'Call',
    validation: '^[0-9]{8,15}$', // validating numbers with 8 to 15 digits
    contactType: 'TEL',
    contactLabels: ['Mobile', 'Cell', 'Work', 'Home', 'Fax', 'Pager'],
  },
  pinterest: {
    name: 'Pinterest',
    example: 'pinterest.com/username',
    label: 'Pinterest URL',
    buttonLabel: 'Follow',
    validation: '^(https?://)?(www\\.)?pinterest\\.com/.*$',
    contactType: 'URL',
  },
  snapchat: {
    name: 'Snapchat',
    prefix: 'snapchat.com/add/',
    example: 'yourusername',
    label: 'Snapchat URL',
    buttonLabel: 'Add',
    validation: '(www\\.)?snapchat\\.com/add/.*$',
    contactType: 'URL',
  },
  soundcloud: {
    name: 'SoundCloud',
    prefix: 'soundcloud.com/',
    example: 'username',
    label: 'SoundCloud URL',
    buttonLabel: 'Follow',
    validation: '^(https?://)?(www\\.)?soundcloud\\.com/.*$',
    contactType: 'URL',
  },
  spotify: {
    name: 'Spotify',
    example: 'open.spotify.com/artist/artistname',
    label: 'Spotify URL',
    buttonLabel: 'Listen',
    validation: '^(https?://)?(www\\.)?open\\.spotify\\.com/.*$',
    contactType: 'URL',
  },
  sms: {
    name: 'SMS',
    example: '0456000000',
    label: 'SMS',
    buttonLabel: 'Text',
    validation: '^[0-9]{8,15}$', // validating numbers with 8 to 15 digits
    contactType: 'TEL',
    contactLabels: ['Mobile', 'Cell', 'Work', 'Home', 'Fax', 'Pager'],
  },
  telegram: {
    name: 'Telegram',
    prefix: 't.me/',
    example: 'username',
    label: 'Telegram URL',
    buttonLabel: 'Message',
    validation: '^(https?://)?(www\\.)?t\\.me/.*$',
    contactType: 'URL',
  },
  threads: {
    name: 'Threads',
    prefix: '@',
    example: 'threadsusername',
    label: 'Threads username',
    buttonLabel: 'Follow',
    validation: '^@[a-zA-Z0-9._]+$',
    contactType: 'URL',
  },
  tiktok: {
    name: 'TikTok',
    prefix: '@',
    example: 'tiktokusername',
    label: 'TikTok username',
    buttonLabel: 'Follow',
    validation: '^@[a-zA-Z0-9._]+$',
    contactType: 'URL',
  },
  twitch: {
    name: 'Twitch',
    prefix: 'twitch.tv/',
    example: 'username',
    label: 'Twitch URL',
    buttonLabel: 'Watch',
    validation: '^(https?://)?(www\\.)?twitch\\.tv/.*$',
    contactType: 'URL',
  },
  twitter: {
    name: 'X/Twitter',
    prefix: '@',
    example: 'yourhandle',
    label: 'X/Twitter handle',
    buttonLabel: 'Follow',
    validation: '^@[a-zA-Z0-9_]{1,15}$',
    contactType: 'URL',
  },
  url: {
    name: 'Website link',
    example: 'yourwebsite.com',
    label: 'Website link',
    buttonLabel: 'Visit',
    validation: '^(https?:\\/\\/)?([\\w\\-]+\\.)+[\\w\\-]+([\\w\\-\\.]+\\/?)*$',
    contactType: 'URL',
    ctaTitle: 'Learn more',
  },
  vimeo: {
    name: 'Vimeo',
    prefix: 'vimeo.com/',
    example: 'username/vimeochannelurl',
    label: 'Vimeo URL',
    buttonLabel: 'Follow',
    validation: '^(https?://)?(www.)?vimeo.com/.*$',
    contactType: 'URL',
  },
  wechat: {
    name: 'WeChat',
    prefix: '',
    example: 'YourWeChatID',
    label: 'WeChat ID',
    buttonLabel: 'Chat',
    validation: '^a-zA-Z+$',
    contactType: 'URL',
  },
  bluesky: {
    name: 'Bluesky',
    prefix: '@',
    example: 'handle.bsky.social',
    label: 'Bluesky handle',
    buttonLabel: 'Follow',
    validation: '^@[a-zA-Z0-9_.]{1,253}$',
    contactType: 'URL',
  },
  whatsapp: {
    name: 'WhatsApp',
    example: '+00000000000',
    label: 'WhatsApp number',
    buttonLabel: 'Chat',
    validation: '^\\+[0-9]{8,15}$', // validating numbers with country code
    contactType: 'URL',
  },
  youtube: {
    name: 'YouTube',
    prefix: 'youtube.com/',
    example: 'channel/youtubechannelurl',
    label: 'YouTube URL',
    buttonLabel: 'Subscribe',
    validation: '^(https?://)?(www\\.)?youtube\\.com/.*$',
    contactType: 'URL',
  },
  line: {
    name: 'Line',
    prefix: 'line.me/ti/p/',
    example: 'username',
    label: 'Line ID',
    buttonLabel: 'Chat',
    validation: '^(https?://)?(www\\.)?line\\.me/ti/p/.*$',
    contactType: 'URL',
  },
}

export const socialsArray = Object.entries(socials)
  .map(([key, value]) => ({
    ...value,
    key,
  }))
  .sort((a, b) => a.name.localeCompare(b.name))
