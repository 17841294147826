import { VideoPlayIcon } from '@fingertip/icons'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { getVimeoId } from './utils'

type Props = {
  websiteUrl?: string
  isModal?: boolean
  className?: string
}

export const VimeoBlock = ({ websiteUrl, isModal }: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const embedId = useMemo(() => getVimeoId(websiteUrl), [websiteUrl])

  if (!embedId) {
    return null
  }

  if (!isModal) {
    return (
      <div className="relative flex h-full items-center justify-center overflow-hidden rounded-page-widget-inner bg-black">
        <iframe
          className="aspect-video w-full"
          src={`https://player.vimeo.com/video/${embedId}`}
          title={t('vimeo_video_player')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          width="100%"
          height="100%"
        />
      </div>
    )
  }

  return (
    <Dialog
      modal={true}
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
      }}
    >
      <DialogTrigger className="flex size-full outline-hidden!">
        <div
          className="flex size-full items-center justify-center overflow-hidden rounded-page-widget-inner"
          style={{
            backgroundImage: `url(https://vumbnail.com/${embedId}.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="rounded-full bg-white/50 backdrop-blur">
            <VideoPlayIcon className="size-fluid-16" />
          </div>
        </div>
      </DialogTrigger>

      <DialogContent className="fixed h-[calc(100vh-100px)] max-h-screen min-w-full items-center justify-center rounded-none! bg-black pb-fluid-8 pt-fluid-14">
        <VisuallyHidden>
          <DialogTitle>Vimeo</DialogTitle>
        </VisuallyHidden>

        <iframe
          className="aspect-video w-full"
          src={`https://player.vimeo.com/video/${embedId}?autoplay=1`}
          title={t('vimeo_video_player')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          width="100%"
          height="100%"
        />
      </DialogContent>
    </Dialog>
  )
}
