export const getYouTubeId = (url?: string | null): string | false => {
  if (!url) {
    return false
  }
  // The following regular expression covers these patterns:
  // 1. https://youtu.be/<id>
  // 2. https://www.youtube.com/watch?v=<id>
  // 3. https://www.youtube.com/v/<id>
  // 4. https://www.youtube.com/embed/<id>
  // 5. https://www.youtube.com/u/<user>/<id>
  // 5. https://www.youtube.com/shorts/<id>
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  // If a match is found and the extracted potential ID has the correct length of 11 (typical YouTube video ID length),
  // return the ID. Otherwise, return false.
  return match && match[2].length === 11 ? match[2] : false
}

export const getVimeoId = (url?: string | null) => {
  if (!url) {
    return false
  }

  var regex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i
  var match = url.match(regex)

  return match && match[1] ? match[1] : false
}
