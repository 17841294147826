export const convertToBlurNumber = (
  blurString: string | undefined | null,
): number => {
  if (!blurString) {
    return 0
  }

  if (blurString === 'unset') {
    return 0
  }

  const match = blurString.match(/(\d+)px/)
  return match ? parseInt(match[1], 10) : 0
}

export const convertToBlurString = (
  blurNumber: number | undefined | null,
): string => {
  return blurNumber && blurNumber ? `${blurNumber}px` : 'unset'
}

export const convertToOverlayString = (
  value: number | undefined | null,
): string => {
  if (!value) {
    return 'none'
  }

  if (value <= -100) {
    return 'rgba(255, 255, 255, 1)'
  } else if (value >= 100) {
    return 'rgba(0, 0, 0, 1)'
  } else if (value < 0) {
    // Convert to a scale from 0 (fully transparent) to 1 (fully white)
    let alpha = Math.abs(value) / 100
    return `rgba(255, 255, 255, ${alpha})`
  } else {
    // Convert to a scale from 0 (fully transparent) to 1 (fully black)
    let alpha = value / 100
    return `rgba(0, 0, 0, ${alpha})`
  }
}

export const convertToOverlayNumber = (
  rgbaString: string | undefined | null,
): number => {
  if (!rgbaString) {
    return 0
  }

  const match = rgbaString.match(/rgba\((\d+), (\d+), (\d+), ([01]?.?\d*)\)/)
  if (!match) {
    return 0 // Default to 0 if the string does not match the expected format
  }

  const [, r, g, b, a] = match.map(Number)
  // Determine if the color is closer to black or white
  // Assuming black or white for simplicity, a more complex analysis could be done for other colors
  if (r === 255 && g === 255 && b === 255) {
    // White, scale alpha to -100 to 0
    return a * 100 * -1
  } else if (r === 0 && g === 0 && b === 0) {
    // Black, scale alpha to 0 to 100
    return a * 100
  }

  // Default to 0 if the color is neither black nor white, though this case might require refinement
  return 0
}
