import { formatPriceInCents } from '@/lib/dinero/utils'

export const getSingleProductPrice = ({
  priceInCents,
  currency,
  salePriceInCents,
  hasSale,
  t,
}: {
  priceInCents: number
  salePriceInCents: number
  hasSale?: boolean
  currency: string
  t: any
}) => {
  const price = formatPriceInCents(priceInCents, currency)
  const salePrice = formatPriceInCents(salePriceInCents, currency)

  const buttonText = (
    <>
      {hasSale && (
        <s className="mr-2">{priceInCents === 0 ? t('free') : price}</s>
      )}
      {hasSale
        ? salePriceInCents === 0
          ? t('free')
          : salePrice
        : priceInCents === 0
          ? t('free')
          : price}
    </>
  )

  return {
    buttonText,
  }
}

export const getProductPrice = ({
  priceInCents,
  maxPriceInCents,
  salePriceInCents,
  hasSale,
  isRange,
  currency,
  isSoldOut,
  t,
}: {
  priceInCents: number
  salePriceInCents: number
  maxPriceInCents?: number
  hasSale?: boolean
  currency: string
  isRange?: boolean
  isSoldOut?: boolean
  t: any
}) => {
  if (isSoldOut) {
    return {
      buttonText: t('sold_out'),
    }
  }

  // Handle single price
  if (!isRange) {
    return getSingleProductPrice({
      priceInCents,
      salePriceInCents,
      hasSale,
      currency,
      t,
    })
  }

  // Handle price range
  const minPrice = formatPriceInCents(priceInCents, currency)
  const maxPrice = formatPriceInCents(maxPriceInCents!, currency)

  if (minPrice === maxPrice) {
    return {
      buttonText: `${minPrice}`,
    }
  }

  return {
    buttonText: `${minPrice} - ${maxPrice}`,
  }
}
