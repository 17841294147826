import { PartialMessage } from '@bufbuild/protobuf'
import type { Video } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/video_pb'
import { useTranslation } from 'react-i18next'

import { fileSrc } from '@/lib/utils/image-loader'

type Props = {
  video: PartialMessage<Video>
  className?: string
}

export const VideoAssetBlock = ({ video }: Props) => {
  const { t } = useTranslation()

  if (!video?.assetSrc) {
    return null
  }

  const src = fileSrc({
    bucket: video?.assetBucket,
    src: video?.assetSrc,
  })

  return (
    <div className="relative flex h-full items-center justify-center overflow-hidden rounded-page-widget-inner">
      <video
        className="size-full object-cover"
        src={src}
        title={t('video_player')}
        playsInline
        autoPlay
        muted
        loop
        width="100%"
        height="100%"
        onMouseEnter={(e) => {
          const video = e.currentTarget
          video.controls = true
        }}
        onMouseLeave={(e) => {
          const video = e.currentTarget
          video.controls = false
        }}
      >
        {t('your_browser_does_no')}
      </video>
    </div>
  )
}
