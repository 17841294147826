import { PartialMessage } from '@bufbuild/protobuf'
import { ContactContent } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/block_schema_pb'

import { socialUrl } from '@/lib/utils/utils'

export const convertToVCardName = (fullName: string): string => {
  // split the full name into parts
  const parts = fullName.split(' ')

  // define the vCard name parts
  let lastName = ''
  let firstName = ''
  let middleName = ''

  // populate the vCard name parts based on the number of parts in the full name
  if (parts.length === 3) {
    firstName = parts[0]
    middleName = parts[1]
    lastName = parts[2]
  } else if (parts.length === 2) {
    firstName = parts[0]
    lastName = parts[1]
  } else if (parts.length === 1) {
    firstName = parts[0]
  }

  // format as per vCard 3.0
  return `N:${lastName};${firstName};${middleName};;;`
}

const escapeVCardValue = (value: string) => {
  return value.replace(/&/g, '\\&')
}

export const createVCard = ({
  content,
  context,
  base64Image,
  siteSlug,
}: {
  content?: PartialMessage<ContactContent>
  context: 'SITE_OWNER' | 'SITE_CONTACT'
  base64Image?: string
  siteSlug: string
}) => {
  let contact = ['BEGIN:VCARD', 'VERSION:3.0']

  if (content?.fullName) {
    contact.push(convertToVCardName(content.fullName))
    contact.push(`FN:${content.fullName}`)
  }

  if (content?.organization) {
    contact.push(`ORG:${content.organization}`)
  }

  if (content?.address) {
    contact.push(`ADR:${content.address}`)
  }

  if (content?.note) {
    contact.push(`NOTE:${content.note}`)
  }

  if (content?.jobTitle) {
    contact.push(`TITLE:${content.jobTitle}`)
  }

  if (base64Image) {
    let imageType
    if (base64Image.startsWith('data:image/png;base64,')) {
      imageType = 'PNG'
    } else if (
      base64Image.startsWith('data:image/jpeg;base64,') ||
      base64Image.startsWith('data:image/jpg;base64,')
    ) {
      imageType = 'JPEG'
    }

    if (imageType) {
      contact.push(
        `PHOTO;TYPE=${imageType};ENCODING=b:${base64Image.replace(
          /^data:image\/(png|jpeg|jpg);base64,/,
          '',
        )}`,
      )
    }
  }

  content?.items?.forEach((item, index) => {
    if (!item.value) {
      return
    }

    const itemLabel = item.label ? `;type=${item.label.toUpperCase()}` : ''
    contact.push(
      `item${index + 1}.${item?.type}${itemLabel}:${socialUrl({
        key: item?.key || '',
        websiteUrl: item.value,
        transformEmail: false,
        transformPhone: false,
      })}`,
    )

    if (item.label) {
      contact.push(`item${index + 1}.X-ABLabel:${item.label}`)
    }
  })

  if (context === 'SITE_OWNER') {
    // Add the Fingertip site URL as a separate item
    const siteUrl = `https://fingertip.com/${siteSlug}`
    contact.push(`item${content?.items?.length ?? 0 + 1}.URL:${siteUrl}`)
    contact.push(`item${content?.items?.length ?? 0 + 1}.X-ABLabel:Fingertip`)
  }

  contact = contact.concat(['END:VCARD'])

  const vCardString = escapeVCardValue(contact.join('\n'))

  const vCardBlob = new Blob([vCardString], {
    type: 'text/vcard;charset=utf-8',
  })

  return {
    vCardString,
    vCardBlob,
  }
}
