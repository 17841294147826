import { PartialMessage } from '@bufbuild/protobuf'
import type { Video } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/video_pb'

import { VideoAssetBlock } from './video-asset-block'
import { VimeoBlock } from './vimeo-block'
import { YoutubeBlock } from './youtube-block'

type Props = {
  video?: PartialMessage<Video>
  isModal?: boolean
  className?: string
}

export const VideoSwitch = ({ video, isModal, className }: Props) => {
  switch (video?.type) {
    case 'VIMEO':
      return (
        <VimeoBlock
          websiteUrl={video?.websiteUrl}
          isModal={isModal}
          className={className}
        />
      )

    case 'YOUTUBE':
      return (
        <YoutubeBlock
          websiteUrl={video?.websiteUrl}
          isModal={isModal}
          className={className}
        />
      )

    case 'ASSET':
      return <VideoAssetBlock video={video} className={className} />

    default:
      return null
  }
}
