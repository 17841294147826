import { PartialMessage } from '@bufbuild/protobuf'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'
import { ArrowUpRightIcon, TriangleExclamationIcon } from '@fingertip/icons'
import React, { ReactNode } from 'react'

import { MediaImage } from '@/components/blocks/shared/media-image'
import { IconBox } from '@/components/shared/icon-box'
import { ButtonDiv } from '@/components/ui/button'
import { cn } from '@/lib/utils/cn'

import { BlockAssetImage } from './block-asset-image'
import {
  getDescriptionMaxLines,
  getFullHeight,
  getTitleMaxLines,
  getWidgetStyle,
  ImagePosition,
  isWidgetMinimum,
} from './utils'

type Props = {
  media?: PartialMessage<Media>
  title?: string
  description?: string
  buttonText?: ReactNode
  warning?: string
  icon?: ReactNode
  w: number
  h: number
  y: number
  buttonIcon: ReactNode | null
  scaleFactor: number
}

export const BlockAssetInner = ({
  media,
  title,
  description,
  buttonText,
  warning,
  icon,
  w,
  h,
  y,
  buttonIcon = (
    <ArrowUpRightIcon className="ml-fluid-2 size-fluid-4 shrink-0" />
  ),
  scaleFactor,
}: Props) => {
  // Layout properties
  const hasImage = w > 1 && !!media?.type
  const widgetStyle = getWidgetStyle(w, h)
  const widgetMinimum = isWidgetMinimum(w, h)
  const isShort = h <= 4
  const HEIGHT = getFullHeight(h, scaleFactor)

  // Determine image position
  let position: ImagePosition = null

  if (hasImage) {
    switch (widgetStyle) {
      case 'small':
        position = 'CENTER'
        break
      case 'medium':
        position = 'RIGHT'
        break
      case 'large':
        position = 'TOP'
        break
    }
  }

  const titleMaxLines = getTitleMaxLines(widgetMinimum, widgetStyle)

  const descriptionMaxLines = getDescriptionMaxLines(
    widgetMinimum,
    widgetStyle,
    h,
    hasImage,
    position,
  )

  // Content properties
  const hasTitle = !!title?.length
  const hasDescription = !!description?.length

  // Calculate content height
  let contentHeight = 12 + 32 + 12 + 12 + 6
  if (hasTitle) contentHeight += 27 * titleMaxLines
  if (hasDescription) contentHeight += 17.5 * descriptionMaxLines
  if (!isShort) contentHeight += 16
  contentHeight *= scaleFactor

  // Calculate base ratio
  let baseRatio = 50
  if (widgetStyle === 'large') {
    baseRatio = ((HEIGHT - contentHeight) / HEIGHT) * 100
  } else if (widgetStyle === 'medium' && w === 3) {
    baseRatio = 45
  }

  return (
    <div
      className={cn('flex size-full p-block relative', {
        'items-center': h <= 3,
        'flex-col': h > 3,
        'justify-center': widgetStyle === 'long' && !title && !description,
      })}
    >
      {hasImage && (position === 'TOP' || position === 'RIGHT') && (
        <BlockAssetImage
          alt={title}
          media={media}
          position={position}
          y={y}
          w={w}
          h={h}
          baseRatio={baseRatio}
          scaleFactor={scaleFactor}
        />
      )}

      {position === 'TOP' && <div className="h-[40%] mb-fluid-2" />}

      <div
        className={cn('flex', {
          'w-1/2': position === 'RIGHT',
          'w-full': position === 'TOP',
          'items-end': widgetStyle === 'small' && hasImage,
          grow: widgetStyle !== 'long' || hasTitle || hasDescription,
        })}
      >
        <div className="flex size-full flex-col">
          {icon && h >= 6 && (
            <div className="flex-1">
              {hasImage && position === 'CENTER' ? (
                <MediaImage
                  width={HEIGHT - contentHeight}
                  height={HEIGHT - contentHeight}
                  media={media}
                  title={title}
                  className="rounded-page-widget-block overflow-hidden object-cover"
                  style={{
                    height: `calc(${HEIGHT}px - ${contentHeight}px)`,
                    width: `calc(${HEIGHT}px - ${contentHeight}px)`,
                  }}
                  priority={y < 6}
                />
              ) : (
                <IconBox
                  className={cn('mb-fluid-3', {
                    'absolute -translate-y-1/2':
                      widgetStyle === 'large' && hasImage,
                  })}
                  style={{
                    top:
                      widgetStyle === 'large' && hasImage
                        ? `${baseRatio}%`
                        : 'unset',
                  }}
                >
                  {icon}
                </IconBox>
              )}
            </div>
          )}

          <div
            className={cn('flex flex-col', {
              'mr-fluid-1': widgetStyle === 'long',
              'mb-fluid-3': widgetStyle !== 'long',
            })}
          >
            {title && (
              <div className="mb-1 flex items-center gap-fluid-2">
                <div
                  className="page-heading text-left text-fluid-lg"
                  style={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: titleMaxLines,
                  }}
                >
                  {title}
                </div>
                {warning && (
                  <div className="flex items-start" title={warning}>
                    <TriangleExclamationIcon className="size-fluid-5 text-warning" />
                  </div>
                )}
              </div>
            )}

            {description && (
              <div
                className="text-left"
                style={{
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: descriptionMaxLines,
                }}
              >
                {description}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="max-w-full">
        <ButtonDiv
          size={isShort ? 'blockSm' : 'block'}
          variant="blockPrimary"
          className={cn('w-full max-w-full', {
            'w-1/2 max-w-1/2!': hasImage && position === 'RIGHT',
            'w-auto!': isShort,
          })}
        >
          <div className="truncate">{buttonText}</div>
          {buttonIcon}
        </ButtonDiv>
      </div>
    </div>
  )
}
