import { PartialMessage } from '@bufbuild/protobuf'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'

import { MediaImage } from './media-image'
import { getFullHeight, getFullWidth } from './utils'

type Props = {
  media?: PartialMessage<Media>
  alt?: string
  baseRatio?: number
  position: 'TOP' | 'RIGHT' | 'CENTER' | null
  y: number
  w: number
  h: number
  scaleFactor: number
}

export const BlockAssetImage = ({
  media,
  position,
  y,
  w,
  h,
  baseRatio = 50,
  alt,
  scaleFactor,
}: Props) => {
  const WIDTH = getFullWidth(w, scaleFactor)
  const HEIGHT = getFullHeight(h, scaleFactor)
  const imageWidth =
    position === 'RIGHT' ? (baseRatio / 100) * WIDTH - 16 * scaleFactor : WIDTH
  const imageHeight = position === 'TOP' ? (baseRatio / 100) * HEIGHT : HEIGHT

  let width: string | number = '100%'
  let height: string | number = '100%'
  let left: string | number = 'unset'
  let right: string | number = 'unset'

  if (position === 'RIGHT') {
    width = `calc(${baseRatio}% - 16px * var(--scale-factor, 1))`
    right = 0
  } else if (position === 'TOP') {
    height = `calc(${baseRatio}%)`
    left = 0
  }

  return (
    <MediaImage
      width={imageWidth}
      height={imageHeight}
      media={media}
      title={alt}
      className="absolute overflow-hidden rounded-page-widget-inner object-cover"
      style={{
        width,
        height,
        left,
        right,
        top: 0,
      }}
      priority={y < 6}
    />
  )
}
