export const convertToTelFormat = (phoneNumber?: string | null) => {
  if (!phoneNumber) {
    return ''
  }

  // Remove all non-numeric and non-plus characters
  let cleaned = phoneNumber.replace(/[^0-9\+]/g, '')

  // If starts with '0' and length is 10, it's a national number (E.g. 0400 123 123)
  if (cleaned.startsWith('0') && cleaned.length === 10) {
    return `+61${cleaned.substring(1)}`
  }

  // If starts with '61' and not '+' (E.g. 61400123123)
  if (cleaned.startsWith('61') && !cleaned.startsWith('+')) {
    return `+${cleaned}`
  }

  // If it's already in international format (starts with '+')
  if (cleaned.startsWith('+')) {
    return cleaned
  }

  return cleaned
}
